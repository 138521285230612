<template>
  <div v-if="load">
    <div class="row">
      <div class="col-md-6">
        <a-form-item label="First Name">
          <a-input v-model:value="first_name" />
        </a-form-item>
      </div>
      <div class="col-md-6">
        <a-form-item label="Last Name">
          <a-input v-model:value="last_name" />
        </a-form-item>
      </div>
    </div>

    <a-form-item label="Company">
      <a-input v-model:value="company_name" />
    </a-form-item>
    <a-form-item label="Address">
      <a-input v-model:value="address" />
    </a-form-item>
    <a-form-item label="City">
      <a-input v-model:value="city" />
    </a-form-item>
    <a-form-item label="State">
      <a-input v-model:value="state" />
    </a-form-item>
    <a-form-item label="Phone">
      <a-input-number v-model:value="phone" />
    </a-form-item>
    <a-form-item
      label="Email"
      name="email"
    >
      <a-input
        v-model:value="email"
        disabled
        type="email"
      />
    </a-form-item>
    <button
      class="btn btn-primary"
      @click="update"
    >
      Update
    </button>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import axios from '@/axios'
export default {
  data() {
    return {
      load: false,
      first_name: '',
      last_name: '',
      address: '',
      company_name: '',
      city: '',
      email: '',
      phone: '',
      primary_contact: '',
      state: '',
    }
  },
  mounted() {
    this.loadItem()
  },
  methods: {
    loadItem() {
      axios.get('/user/me').then(async (res) => {
        const item = await res.data
        const reg = item.registration
        this.load = true
        if (reg) {
          this.first_name = reg.first_name
          this.last_name = reg.last_name
          this.company_name = reg.company_name
          this.address = reg.address
          this.state = reg.state
          this.phone = reg.phone
          this.city = reg.city
        }
        this.email = item.email
      })
    },
    update() {
      axios
        .put('/user/me', this.$data)
        .then((res) => {
          Swal.fire({
            title: 'Success!',
            text: 'Info updated',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        })
        .catch((err) => {
          const error = err.response.data.message
          let messages
          if (Array.isArray(error)) messages = [...err.response.data.message]
          else messages = err.response.data.message
          Swal.fire({
            title: 'Error!',
            text: messages,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>

<style lang="scss">
.ant-form-item {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  .ant-form-item-label {
    line-height: 15px !important;
    label {
      margin-bottom: 0;
    }
  }
  .ant-form-item-control,
  input,
  .ant-form-item-control-wrapper,
  .ant-input-number {
    width: 100% !important;
  }
}
</style>
